import { extend } from 'vee-validate';
import { required, email, min, max, confirmed, oneOf, numeric } from 'vee-validate/dist/rules';
import { parsePhoneNumber } from 'awesome-phonenumber'

extend('required', {
  ...required,
  message: 'Dit veld is verplicht.'
});
extend('min', {
  ...min,
  message: 'Dit veld moet minimaal {length} karakters lang zijn.'
});
extend('max', {
  ...max,
  message: 'Dit veld mag maximaal {length} karakters lang zijn.'
});
extend('email', {
  ...email,
  message: 'Een geldig e-mailadres is verplicht.'
});
extend('confirmed', {
  ...confirmed,
  message: 'Het veld moet overeenkomen.'
});
extend('oneOf', {
  ...oneOf,
  message: 'Het veld moet overeenkomen.'
});
extend('numeric', {
  ...numeric,
  message: 'Dit veld is numeriek.'
});

const isCurrency = value => {
  //const pattern = /^(\d+|\d{1,3}(\.\d{3})*)(,\d+)?$/;
  const pattern = /^(\d+|\d{1,3}(\.\d{3})*)(.\d+)?$/;
  return pattern.test(`${value}`);
}

extend("currency", {
  validate: isCurrency,
  message: 'Onjuist bedrag.',
  getMessage: (field, params, data) => data.message
});

const isPhone = value => {
  let pn = parsePhoneNumber( value, { regionCode: 'NL' } );
  return pn.valid;
}

extend("phone", {
  validate: isPhone,
  message: 'Onjuist telefoonnummer.',
  getMessage: (field, params, data) => data.message
});