import Vue from 'vue'

import Web from './layouts/Web.vue'
import Account from './layouts/Account.vue'
import Widget from './layouts/Widget.vue'
import Login from './layouts/Login.vue'

Vue.component('web', Web);
Vue.component('account', Account);
Vue.component('widget', Widget);
Vue.component('login', Login);