import Vue from 'vue'
import axios from 'axios'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/widget/:host',
    meta: { layout: 'widget', requiresAuth: false },
    component: () => import( '../views/Widget.vue'),
    children: [
      {
        path: '',
        component: () => import( '../views/widget/Index.vue'),
        meta: { layout: 'widget', requiresAuth: false },
      },
      {
        path: 'schedule/:location?',
        component: () => import( '../views/widget/Schedule.vue'),
        meta: { layout: 'widget', requiresAuth: false },
      },
    ]
  },
  {
    path: '/:host/account',
    meta: { layout: 'account', requiresAuth: true },
    component: () => import( '../shared/views/Account.vue'),
    children: [
      {
        path: 'index',
        component: () => import( '../shared/views/account/Index.vue'),
        meta: { layout: 'account', requiresAuth: true },
      },
      {
        path: 'details',
        component: () => import( '../shared/views/account/Index.vue'),
        meta: { layout: 'account', requiresAuth: true },
      },
      {
        path: 'orders',
        component: () => import( '../shared/views/account/Orders.vue'),
        meta: { layout: 'account', requiresAuth: true },
      },
      {
        path: 'orders/past',
        component: () => import( '../shared/views/account/OrdersPast.vue'),
        meta: { layout: 'account', requiresAuth: true },
      },
      {
        path: 'wallet',
        component: () => import( '../shared/views/account/Wallet.vue'),
        meta: { layout: 'account', requiresAuth: true },
      },
      {
        path: 'login',
        component: () => import( '../views/account/Login.vue'),
        meta: { layout: 'login', requiresAuth: false },
      },
      {
        path: 'verify',
        component: () => import( '../views/account/Verify.vue'),
        meta: { layout: 'login', requiresAuth: false },
      },
      {
        path: 'register',
        component: () => import( '../views/account/Register.vue'),
        meta: { layout: 'login', requiresAuth: false },
      },
      {
        path: 'logout',
        component: {
          beforeRouteEnter(to, from, next) {
            to;
            from;
            Vue.$cookies.remove('token');
            localStorage.removeItem('type');
    
            return next('/' + to.params.host + '/');
          }
        }
      },
    ]
  },
  {
    path: '/error',
    name: 'Error',
    meta: { layout: 'web', requiresAuth: false },
    component: () => import( '../views/pages/NotFound.vue')
  },
  {
    path: '/:host',
    meta: { layout: 'web', requiresAuth: false },
    component: () => import( '../views/Shop.vue'),
    children: [
      {
        path: '',
        component: () => import( '../views/hosts/Agenda.vue'),
        meta: { layout: 'web', requiresAuth: false },
      },
      {
        path: 'event/:event',
        component: () => import( '../shared/views/shop/Event.vue'),
        meta: { layout: 'web', requiresAuth: false },
      },
      {
        path: 'event/:event/:slot',
        component: () => import( '../shared/views/shop/Tickets.vue'),
        meta: { layout: 'web', requiresAuth: false },
      },
      {
        path: 'event/:event/:slot/checkout',
        meta: { layout: 'web', requiresAuth: false },
        component: () => import( '../shared/views/shop/Checkout.vue'),
      },
      {
        path: 'order/:order_id',
        component: () => import( '../shared/views/shop/Order.vue'),
        meta: { layout: 'web', requiresAuth: false },
      },
    ]
  },
  {
    path: '/*',
    name: 'NotFound',
    meta: { layout: 'web', requiresAuth: false },
    component: () => import( '../views/pages/NotFound.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else if (to.hash) {
      return {
        selector: to.hash,
      }
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  // instead of having to check every route record with
  // to.matched.some(record => record.meta.requiresAuth)
  if (to.meta.requiresAuth) {

    let check_url = "https://api.tixgo.nl/customer/check";

    axios.post(check_url, { }, {
      headers: {'Authorization': `Bearer ${Vue.$cookies.get('token')}`}
    }).then(response => { 
      if (response.data.status != 'success') {
        Vue.$cookies.remove('token');
        localStorage.removeItem('type');

        return next('/account/login');
      } else {
        next();
      }
    });
  } else {
    // Within else, because else route is forwarded first always.
    next();
  }
})

router.onError(error => {
  if (confirm('An update is available or a network error occurred. Do you want to reload?')) {
    window.location.reload();
    error;
  }
})

export default router