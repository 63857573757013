<template>
    <div>
        <b-navbar class="main-navbar" toggleable="lg">
            <b-navbar-brand :to="`/` + $route.params.host" v-if="$route.params.host && getShop().theme.header_logo" class="text-white p-0 fs-3">
                {{ getShop().organisation.name }}
                <!-- <img alt="Tixgo" :src="`https://api.tixgo.nl/files/` + getShop().theme.header_logo" width="150" /> -->
            </b-navbar-brand>
            <b-navbar-brand href="https://www.tixgo.nl" class="p-0" v-else>
                <img alt="Tixgo" src="../assets/logo_white.svg" width="110" />
            </b-navbar-brand>
            
            <!--
            <b-collapse id="main-collapse" is-nav>
                <b-navbar-nav class="ml-auto">
                    <b-nav-item-dropdown text="Categorieën">
                        <b-nav-item to="/category/muziek">Muziek</b-nav-item>
                        <b-nav-item to="/category/comedy">Comedy</b-nav-item>
                        <b-nav-item to="/category/sport">Sport</b-nav-item>
                        <b-nav-item to="/category/festivals">Festivals</b-nav-item>
                        <b-nav-item to="/category/familie">Familie</b-nav-item>
                        <b-nav-item to="/category/theater-cultuur">Theater & Cultuur</b-nav-item>
                        <b-nav-item to="/category/beurzen-exposities">Beurzen & Exposities</b-nav-item>
                    </b-nav-item-dropdown>
                </b-navbar-nav>
            </b-collapse>
            -->
            <template>
                <div class="ml-auto">
                    <!--
                    <b-nav-form>
                        <b-form-input size="sm" class="mr-sm-2" placeholder="Search"></b-form-input>
                    </b-nav-form>
                    -->
                    <template v-if="$route.params.host">
                        <template v-if="isLoggedIn()">
                            <b-button variant="outline-light" class="mr-lg-2" v-b-toggle.sidebar-account>
                                <span>Mijn Account</span>
                            </b-button>       
                        </template>
                        <template v-else>
                            <b-button variant="outline-light" class="mr-lg-2" :to="`/` + $route.params.host + `/account/login`">
                                <span>Log in/Registreren</span>
                            </b-button>
                        </template>
                    </template>
                </div>
            </template>

            <!--
            <b-button @click="toggleSidebar" class="navbar-toggler">
                <span class="navbar-toggler-icon"></span>
            </b-button>
            -->
        </b-navbar>
        <b-sidebar
            id="sidebar-account"
            backdrop-variant="dark"
            backdrop
            shadow
            right
            lazy
            >
            <ProfileSidebar :path="'/' + $route.params.host + '/account'" />
        </b-sidebar>
    </div>
</template>

<script>
    import { mapActions } from 'pinia'
    import { useShopStore } from '@/stores/shop'
    import ProfileSidebar from '@/shared/components/ProfileSidebar'

    export default {
        components: {
            ProfileSidebar
        },
        methods: {
            ...mapActions(useShopStore, ['getShop','toggleSidebar']),
            isLoggedIn: function() {
                if (this.$cookies.get('token')) {
                    return true;
                }
                return false;
            },
        },
    }
</script>

<style scoped>
.main-navbar{
    z-index:1000;
    background-color:var(--primary-color);
}
</style>