<template>
    <router-view />
</template>

<script>
    export default {
        metaInfo: {
            titleTemplate: '%s | Tixgo'
        },
        components: {},
        methods: {}
    }
</script>