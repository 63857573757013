import Vue from 'vue'
import App from './App.vue'
import router from './router'
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/browser";
import VueCookies from 'vue-cookies'
import VueTheMask from 'vue-the-mask'

import ToastPlugin from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';
Vue.use(ToastPlugin)

// default options config: { expires: '1d', path: '/', domain: '', secure: '', sameSite: 'Lax' }
if (location.hostname === "localhost" || location.hostname === "127.0.0.1") {
  Vue.use(VueCookies);
} else {
  Vue.use(VueCookies, { domain: '.tixgo.nl' });
}

Vue.use(VueTheMask)

import '@/iframeResizer.contentWindow.min.js';

Sentry.init({
  Vue,
  dsn: "https://a83f0bde2f05406193e17efb37e6a563@o4504871867514880.ingest.sentry.io/4504871869284352",
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: ["api.tixgo.nl", "shop.tixgo.nl"],
    }),
  ],
  tracesSampleRate: 1.0,
  enabled: process.env.NODE_ENV !== 'development',
});

import { createPinia, PiniaVuePlugin } from 'pinia'

import "./meta"
import "./filters"
import "./validations"
import "./fonts"
import "./layouts"
import "./boostrap"
import "./styles"
import "./axios"

Vue.config.productionTip = false

const pinia = createPinia()
Vue.use(PiniaVuePlugin)

new Vue({
  router,
  pinia,
  render: h => h(App),
}).$mount('#app')