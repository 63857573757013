import Vue from 'vue'
import Axios from 'axios'
import VueCookies from 'vue-cookies'

// default options config: { expires: '1d', path: '/', domain: '', secure: '', sameSite: 'Lax' }
if (location.hostname === "localhost" || location.hostname === "127.0.0.1") {
  Vue.use(VueCookies);
} else {
  Vue.use(VueCookies, { domain: '.tixgo.nl' });
}

Axios.defaults.headers.common = {
  'Authorization': `Bearer ${Vue.$cookies.get('token')}`,
  'Cache-Control': 'no-cache',
  'Pragma': 'no-cache',
  'Expires': '0',
}

Vue.prototype.$axios = Axios;