import { defineStore } from 'pinia'

export const useShopStore = defineStore('counter', {
  state: () => ({
    shop: {
      config: {},
      theme: {},
      organisation: {},
      events: [],
      slots: [],
    },
    sidebar_visible: false,
  }),
  actions: {
    setShop(shop) {
      this.shop = shop;
    },
    getShop() {
        return this.shop;
    },
    toggleSidebar() {
      if (this.sidebar_visible) {
        this.sidebar_visible = false;
      } else {
        this.sidebar_visible = true;
      }
    },
  }
})