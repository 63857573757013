<template>
    <div class="sidebar sticky-top">
        <div class="px-3 py-2">
            <span class="d-block text-muted">Welkom terug!</span>
            <span class="d-block h4">{{ user.firstname }}</span> 
        </div>
        <nav>
            <b-nav>
                <li class='sub-menu border-top'>
                    <router-link :to="path + '/orders'" class="text-dark">
                        <font-awesome-icon icon="ticket" class="mr-2" />
                        Mijn Tickets
                        <font-awesome-icon icon="caret-down" class="float-right" />
                    </router-link>
                    <ul class="ml-0">
                        <li><router-link :to="path + '/orders'" class="text-dark pl-5">Komende evenementen</router-link></li>
                        <li><router-link :to="path + '/orders/past'" class="text-dark pl-5">Afgelopen evenementen</router-link></li>
                    </ul>
                </li>
                <li class='sub-menu border-top'>
                    <router-link :to="path + '/details'" class="text-dark">
                        <font-awesome-icon icon="user" class="mr-2" />
                        Mijn profiel
                        <font-awesome-icon icon="caret-down" class="float-right" />
                    </router-link>
                    <ul class="ml-0">
                        <li><router-link :to="path + '/details'" class="text-dark pl-5">Profiel details</router-link></li>
                        <li><router-link :to="path + '/wallet'" class="text-dark pl-5">Cadeaukaartsaldo</router-link></li>
                    </ul>
                </li>
                <li class="border-top">
                    <router-link class="text-danger" :to="path + '/logout'">
                        <font-awesome-icon icon="sign-out" class="mr-2" />
                        Afmelden
                    </router-link>
                </li>
            </b-nav>
        </nav>
    </div>
</template>

<script>
export default({
    props: {
        path: {
            type: [String],
            default: '/account',
            required: false,
        }
    },
    data() {
        return {
            user: {},
        }
    },
    methods: {
        getUser: function() {
            this.$axios.get("https://api.tixgo.nl/customer")
                .then( response => {
                    this.user = response.data.customer;
                }
            );
        },
    },
    created() {
        this.getUser();
    }
})
</script>

<style scoped>
.router-link-active{}
.router-link-exact-active{
    background-color:#eee;
}
.sidebar{
    border-radius:0px;
}
.sidebar /deep/ nav {
	position: relative;
}

.sidebar /deep/ nav ul {
	list-style: none;
	margin: 0;
	padding: 0;
    display:block;
}
.sidebar /deep/ nav ul li {
  /* Sub Menu */
}
.sidebar /deep/ nav ul li a {
	display: block;
	padding: 10px 15px;
	text-decoration: none;
	-webkit-transition: 0.2s linear;
	-moz-transition: 0.2s linear;
	-ms-transition: 0.2s linear;
	-o-transition: 0.2s linear;
	transition: 0.2s linear;
}
.sidebar /deep/ nav ul li a:hover {
	background: #eee;
}
.sidebar /deep/ nav ul li a .fa {
	width: 16px;
	text-align: center;
	margin-right: 5px;
	float:right;
}
.sidebar /deep/ nav ul ul {}
.sidebar /deep/ nav ul li ul li a {
	
	border-left: 4px solid transparent;
	padding: 10px 20px;
}
.sidebar nav ul li ul li a:hover {

	border-left: 4px solid #eee;
}
</style>